<template>
    <div class="nevs-content" v-if="loaded">
        <div class="nevs-content-top-buttons">
            <NevsButton @click="saveClick()" class="success"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
            <NevsButton @click="backClick" class="error"><i class="fa-solid fa-arrow-left"></i> {{
                    $LANG.Get('buttons.back')
                }}
            </NevsButton>
        </div>
        <NevsCard class="client-form">
            <NevsTextField :error="clientValidation.name" :label="$LANG.Get('fields.name') + ':'"
                           v-model="client.name"></NevsTextField>
            <NevsTextField v-if="client.id != 0" readonly :error="clientValidation.hash" :label="$LANG.Get('fields.hash') + ':'"
                           v-model="client.hash"></NevsTextField>
            <NevsTextField :error="clientValidation.api_token" :label="$LANG.Get('fields.apiToken') + ':'"
                           v-model="client.api_token"></NevsTextField>
            <NevsSelect v-model="client.client_status_id" :ajax="'select/client-statuses'"
                        :label="$LANG.Get('fields.clientStatus')"></NevsSelect>
        </NevsCard>

        <NevsCard class="user-form" v-if="client.id == 0">
            <NevsTextField :error="userValidation.first_name" :label="$LANG.Get('fields.firstName') + ':'"
                           v-model="user.first_name"></NevsTextField>
            <NevsTextField :error="userValidation.last_name" :label="$LANG.Get('fields.lastName') + ':'"
                           v-model="user.last_name"></NevsTextField>
            <NevsTextField :error="userValidation.entrance" :label="$LANG.Get('fields.entrance') + ':'"
                           v-model="user.entrance"></NevsTextField>
            <NevsTextField :error="userValidation.floor" :label="$LANG.Get('fields.floor') + ':'"
                           v-model="user.floor"></NevsTextField>
            <NevsTextField :error="userValidation.username" :label="$LANG.Get('fields.username') + ':'"
                           v-model="user.username"></NevsTextField>
            <NevsTextField :error="userValidation.password" :label="$LANG.Get('fields.password') + ':'"
                           v-model="user.password"></NevsTextField>
        </NevsCard>
    </div>
</template>

<script>
import Client from '@/models/Client';
import ClientUser from '@/models/ClientUser';
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsButton from "@/components/nevs/NevsButton";
import NevsCard from "@/components/nevs/NevsCard";
import NevsSelect from "@/components/nevs/NevsSelect.vue";

export default {
    name: "EntityClient",
    components: {NevsSelect, NevsTextField, NevsButton, NevsCard},
    data() {
        return {
            loaded: false,
            client: new Client({id: this.$route.params.id}),
            clientValidation: new Client({}, true),
            user: new ClientUser({}),
            userValidation: new ClientUser({}, true),
            changePassword: false
        }
    },
    methods: {
        backClick() {
            if (window.history.length === 1) {
                window.close();
            } else {
                this.$router.back();
            }
        },
        saveClick() {
            let vm = this;
            let api = (this.client.id == 0) ? this.$CLIENT_API : this.$API;
            let action = (this.client.id == 0) ? 'post' : 'put';
            let endpoint = (this.client.id == 0) ? 'public/register' : 'clients/' + this.client.id;
            let payload = {
                id: this.client.id,
                name: this.client.name,
                api_token: this.client.api_token,
                hash: this.client.hash,
                client_status_id: this.client.client_status_id,
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                ownership: [
                    {
                        entrance: this.user.entrance,
                        floor: this.user.floor
                    }
                ],
                username: this.user.username,
                password: this.user.password
            };

            api.APICall(action, endpoint, payload, (data, success) => {
                if (success) {
                    vm.$CROSS_TAB_BUS.TriggerEvent('reload-clients', {});
                    vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                    vm.backClick();
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {
                        text: vm.$LANG.Get('alerts.serverError'),
                        type: 'alert'
                    });
            }
            });
        }
    },
    mounted() {
        this.$store.commit('selectMenu', 'clients');
        this.$store.commit('selectSubMenu', null);

        let vm = this;
        if (this.client.id != 0) {
            this.$API.APICall('get', 'clients/' + this.client.id, {}, (data, success) => {
                if (success) {
                    if (data.client !== null) {
                        vm.client.Fill(data.client);
                        vm.$store.commit('setBreadcrumbs', [
                            {
                                label: vm.$LANG.Get('modules.clients'),
                                link: '/clients'
                            },
                            {
                                label: vm.client.name,
                                link: null
                            }
                        ]);
                        vm.$nextTick(()=>{
                            vm.loaded = true;
                        });
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
                vm.$nextTick(() => {
                    vm.loaded = true;
                });
            });
        } else {
            vm.$nextTick(()=>{
                vm.loaded = true;
            });
        }
    }
}
</script>

<style scoped>
.client-form {
    max-width: 500px;
}

.user-form {
    margin-top: 10px;
    max-width: 500px;
}


</style>